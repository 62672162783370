<template>
  <div class="upload_graphocs_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">发布图文</div>
      <router-link class="rightBtn" to="/releaseRules">发布规则?</router-link>
    </div>
    <div class="main">
      <div class="input_box">
        <van-field
          class="titleText"
          v-model="title"
          rows="4"
          autosize
          type="textarea"
          maxlength="150"
          placeholder="请输入标题,至少3个字"
          show-word-limit
        />
      </div>
      <div class="content">
        <div class="subTitle">添加图片</div>
        <UploadFile @uploadSuccess="uploadSuccess" @delImg="delImg" ref="UploadFile" :multiple="true" />
        <div class="location_box flex-between">
          <div class="location_text">定位</div>
          <router-link class="location" to="/city">
            <div class="ellipsis">{{ location }}</div>
            <svg-icon icon-class="toTheRight" />
          </router-link>
        </div>
        <div class="location_box flex-between">
          <div class="location_text">制定价格</div>
          <div class="price location" @click="isRewardShow = true">
            <svg-icon icon-class="gold" v-if="price > 0" />
            {{ price > 0 ? price : '免费' }}

            <svg-icon icon-class="toTheRight" />
          </div>
        </div>
        <div class="flex-between tipic">
          <div class="selectTitle">
            <svg-icon icon-class="tip_hot" />
            <span>话题选择</span>
          </div>
          <router-link class="more" to="/topicMore">
            <span>更多</span>
            <svg-icon icon-class="toTheRight" />
          </router-link>
        </div>
        <div class="tipic_content">
          <div class="tipic_item" v-for="item in tags" :key="item.id">
            <div class="ellipsis tipc_text">
              <svg-icon icon-class="tag" />
              {{ item.name }}
            </div>
            <div class="tic_close" @click="delTag(item.name)">
              <svg-icon icon-class="close8" />
            </div>
          </div>
        </div>
        <div style="height: 120px"></div>
      </div>
      <div class="publish_box">
        <div class="publish" @click="publish">立即发布</div>
      </div>
    </div>
    <!-- 设置金额-->
    <div class="reward_warp">
      <van-overlay :show="isRewardShow" />
      <transition name="fade">
        <div class="reward_pop_box" v-if="isRewardShow">
          <div class="reward_pop">
            <h2>设定价格</h2>
            <div class="reward">
              <div :class="['reward_item', { active: i === curMoneyIndex }]" v-for="(amount, i) in amountMatrix" :key="i" @click="changeAmount(i)">
                <svg-icon icon-class="gold" />
                {{ amount }}
              </div>
              <div :class="['reward_item', { active: 5 === curMoneyIndex }]" @click="changeAmount(5)">
                <van-field v-model="customizeMoney" placeholder="其他金额" type="digit" @input="changeMoney" />
              </div>
            </div>
            <div class="balance"></div>
            <div class="reward_btn" @click="setPrice">确定</div>
          </div>
          <div class="close_box">
            <div class="close" @click="isRewardShow = false">
              <svg-icon icon-class="y_close" />
            </div>
          </div>
        </div>
      </transition>
    </div>
    <FullScreenLoading :isLoadingShow="isLoadingShow" />

    <!-- 提示弹窗 -->
    <van-popup class="dialog" v-model="promptShow" :close-on-click-overlay="false">
      <div class="dialogContent">
        <div class="dialogTitle">
          <span>温馨提示</span>
        </div>
        <div class="dialogText">
          <p>今日上传已达上限</p>
          <p>开通会员/大V无限上传</p>
        </div>
        <div class="btnGroup">
          <img @click="$router.push('/memberCentre?t=vip')" class="btn toBinding" src="@/assets/png/toOpenMember.png" />
          <img @click="$router.push('/myCertification')" class="btn toPrepaid" src="@/assets/png/toOpenBigV.png" />
        </div>
      </div>
      <div class="closeBtn" @click="promptShow = false">
        <img src="@/assets/png/promptClose.png" />
      </div>
    </van-popup>
  </div>
</template>
<script>
import UploadFile from '@/components/UploaderFile';
import { getLocalItem } from '@/utils/longStorage';
import { Overlay, Field, Toast } from 'vant';
import { mapGetters } from 'vuex';
import FullScreenLoading from '@/components/FullScreenLoading';
import { publishPost } from '@/api/community';

export default {
  name: 'uploadGraphics',
  components: {
    UploadFile,
    [Field.name]: Field,
    [Overlay.name]: Overlay,
    FullScreenLoading,
  },
  computed: {
    ...mapGetters({
      tags: 'tags',
    }),
  },
  activated() {
    this.location = getLocalItem('city') ? getLocalItem('city') : '选择城市';
  },
  data() {
    return {
      title: '',
      isRewardShow: false, //是否打开大赏弹窗
      amountMatrix: [1, 5, 10, 20, 50],
      customizeMoney: '', //手动输入金额
      curMoneyIndex: -1,
      price: 0, //设置的金额
      imgList: [],
      isLoadingShow: false,
      location: getLocalItem('city') ? getLocalItem('city') : '选择城市',
      promptShow: false,
    };
  },
  created() {
    this.amountMatrix = this.$store.getters.appConfig.rewardMoney ? this.$store.getters.appConfig.rewardMoney : [2, 10, 20, 50, 100];
  },
  methods: {
    changeAmount(i) {
      this.curMoneyIndex = i;
    },
    changeMoney() {
      if (this.customizeMoney > 10000) {
        this.customizeMoney = 10000;
        Toast('作品金额最高只能设置10000金币');
      }
    },
    //删除标签
    delTag(name) {
      this.$store.commit('commnity/DELITEM_TAG', name);
    },
    uploadSuccess(img) {
      this.imgList.push(img);
    },
    delImg(index) {
      this.imgList.splice(index, 1);
    },
    //设置价格
    setPrice() {
      if (this.curMoneyIndex === 5) {
        if (this.customizeMoney < 5) {
          Toast('最低设置5金币');
          return;
        }
        this.price = this.customizeMoney;
        this.isRewardShow = false;
        this.curMoneyIndex = -1;
        this.customizeMoney = '';
        return;
      }
      if (this.curMoneyIndex >= 0) {
        this.price = this.amountMatrix[this.curMoneyIndex];
        this.curMoneyIndex = -1;
        this.customizeMoney = '';
        this.isRewardShow = false;
        return;
      }
    },
    async publish() {
      let req = {
        title: this.title.trim(),
        newsType: 'COVER',
        tags: this.tags.map((e) => e.id),
        location: {
          city: this.location,
        },
        seriesCover: this.imgList,
        coins: parseInt(this.price),
      };
      if (req.title.length == 0) {
        Toast('请输入标题');
        return;
      }
      if (req.seriesCover.length == 0) {
        Toast('请添加图片');
        return;
      }
      if (req.location.city == '选择城市') {
        Toast('请选择城市');
        return;
      }
      if (req.coins > 10000) {
        Toast('作品金额最高只能设置10000金币');
        return;
      }
      if (req.tags.length == 0) {
        Toast('请选择话题');
        return;
      }

      try {
        this.isLoadingShow = true;
        let res = await this.$Api(publishPost, req);
        this.isLoadingShow = false;
        if (res.code == 200) {
          this.imgList = [];
          this.title = '';
          this.price = 0;
          this.$refs.UploadFile.clearImg();
          this.$store.commit('commnity/CLEARALL_TAGS');
          this.$router.replace('/creationCenter');
          Toast('发布成功');
          return;
        }
        this.promptShow = true;
        // Toast(res.tip || "发布失败");
      } catch (e) {
        this.isLoadingShow = false;
        Toast('发布失败');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.upload_graphocs_warp {
  height: 100%;

  .van-cell::after {
    border: none;
  }

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
  }

  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    .input_box {
      padding: 10px 16px 8px;
      background-color: #f5f5f5;
    }
    .titleText {
      background: #ffffff;
      border-radius: 8px;

      /deep/ .van-field__control {
        color: #666666;
        overflow: hidden;
      }

      /deep/ .van-field__control {
        font-size: 16px;
      }

      ::placeholder {
        color: #939bac;
        font-size: 14px;
      }
    }

    .content {
      background-color: #ffffff;
      padding: 0 12px;
    }
    .subTitle {
      font-size: 16px;
      padding: 10px 0 11px 0;
      color: #333333;
      margin-top: 10px;
      // text-align: center;
    }

    .location_box {
      padding-top: 20px;

      .location_text {
        font-size: 16px;
        color: #333333;
      }

      .location {
        font-size: 14px;
        color: #999999;
        max-width: 200px;
        display: flex;
        align-items: center;

        > svg {
          font-size: 16px;
          flex-shrink: 0;
        }
      }
    }

    .tipic {
      padding: 20px 0 10px 0;
      align-items: center;

      .selectTitle {
        font-size: 16px;
        color: #666666;
        svg {
          font-size: 18px;
          padding-right: 4px;
        }
      }

      .more {
        font-size: 14px;
        color: #999999;
        svg {
          font-size: 16px;
        }
      }
    }

    .tipic_content {
      display: flex;
      flex-wrap: wrap;
      grid-column-gap: 6px;
      text-align: center;
      height: 41px;
      line-height: 41px;

      .tipic_item {
        display: inline-block;
        max-width: 300px;
        height: 24px;
        font-size: 12px;
        background: #e8e8e8;
        border-radius: 20.5px;
        position: relative;
        line-height: 24px;
        margin: 0 5px 8px;
        color: #666666;

        .tipc_text {
          padding-left: 12px;
          padding-right: 32px;
        }

        .tic_close {
          position: absolute;
          top: 4px;
          right: 10px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          > svg {
            font-size: 16px;
          }
        }
      }
    }

    > h2 {
      font-size: 17px;
      padding: 20px 0 10px 0;
    }
  }

  .publish_box {
    position: fixed;
    bottom: 30px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 540px;

    .publish {
      width: 344px;
      height: 38px;
      color: white;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff678f;
      border-radius: 4px;
    }
  }

  //  大赏
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
    .fade-leave-to

    /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .reward_pop_box {
    position: fixed;
    max-height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    z-index: 12;
    border-radius: 17px;
  }

  /deep/ .van-overlay {
    z-index: 11;
  }

  .reward_pop {
    width: 294px;
    background: url('../../../assets/png/reward_bg.png') no-repeat;
    background-size: cover;
    border-radius: 17px;
    color: #171717;
    padding: 0 15px 24px;
    position: relative;

    h2 {
      font-size: 16px;
      padding: 18px 0 20px 0;
      text-align: center;
      color: #333333;
    }

    .reward {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 8px;
      grid-column-gap: 8px;

      .reward_item {
        text-align: center;
        //border: 2px solid #ff7600;
        border-radius: 4px;
        background: #fff4f7;
        height: 39px;
        line-height: 39px;
        font-size: 18px;

        /deep/ .van-field {
          width: 100%;
          height: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          background: #fff4f7;
        }

        /deep/ .van-field__control {
          text-align: center;
        }
      }

      .active {
        background: #ff678f;
        color: #fff;
      }
    }

    .balance {
      font-size: 12px;
      padding: 16px 0 25px 0;
      color: #666666;
    }

    .reward_btn {
      width: 228px;
      height: 36px;
      line-height: 36px;
      background: #ff678f;
      font-size: 15px;
      text-align: center;
      border-radius: 20px;
      color: white;
      margin: 0 auto;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .close_box {
    position: absolute;
    bottom: -70px;
    width: 100%;
    display: flex;
    justify-content: center;

    .close {
      svg {
        font-size: 33px;
      }
    }
  }
}

.dialog {
  height: 4.43rem;
  border-radius: 0.2rem;
  background: none;

  .dialogContent {
    background-image: url('../../../assets/png/promptShowBg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 7.04rem;
    height: 3.52rem;
    border-radius: 0.2rem;

    .dialogTitle {
      font-size: 0.4rem;
      color: rgb(132, 132, 132);
      display: flex;
      justify-content: center;
      padding-top: 0.3rem;
    }

    .dialogText {
      font-size: 0.38rem;
      color: rgb(100, 100, 100);
      padding-top: 0.18rem;
      font-weight: bold;
      text-align: center;

      .vip {
        font-size: 0.45rem;
        color: rgb(254, 127, 15);
      }
    }

    .btnGroup {
      width: 100%;
      display: flex;
      position: absolute;
      top: 2.28rem;
      display: flex;
      justify-content: center;

      .toBinding {
        //margin-left: 0.76rem;
      }

      .toPrepaid {
        margin-left: 0.22rem;
      }

      .btn {
        width: 2.76rem;
        height: 0.84rem;
      }
    }
  }

  .closeBtn {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;

    img {
      width: 0.71rem;
      height: 0.71rem;
    }
  }
}
</style>
